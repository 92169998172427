<div class="w-100 h-100 d-flex flex-column">
  <ngx-heka-menu-bar [accountMenuItems]="accountMenu" [helpLink]="env.links.help"
                     [userEmail]="authService.getUserInfo()?.email">
    <img src="../../../assets/img/hekatron-icons/dashboard.png" menu-img>
  </ngx-heka-menu-bar>
  <ngx-heka-no-app-access [title]="'OAUTH.NO_ACCESS.TITLE'|translate"
                          [description]="'OAUTH.NO_ACCESS.DESCRIPTION'|translate">
    <button mat-flat-button color="primary" (click)="authService.logout()">{{'OAUTH.LOGIN_BTN' | translate}}</button>
  </ngx-heka-no-app-access>
</div>
