import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HekaSnackBarComponent } from 'ngx-heka-design-lib';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly snackBarDuration = 3000;

  constructor(private snackBar: MatSnackBar) {
  }

  showSuccess(message: string): void {
    this.snackBar.openFromComponent(HekaSnackBarComponent, {
      duration: this.snackBarDuration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: {message},
      panelClass: 'success-notification'
    });
  }

  showError(message: string, duration: number = 0): void {
    this.snackBar.openFromComponent(HekaSnackBarComponent, {
      duration: 0,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: {message},
      panelClass: 'error-notification'
    });
  }
}
