import { L1L3AuthService } from 'ngx-auth-util-lib';
import {
	AccountMenuItem,
	BasicAccountMenuItems,
	DefaultMenuItem,
} from 'ngx-heka-design-lib';
import { MatomoTracker } from 'ngx-matomo-client';
import { Subscription } from 'rxjs';

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/local/environment';
import { ApplicationDTO, ApplicationIds } from '../../models/application';
import { APP_CONFIGS, AppActionItem } from '../../models/appmenu';
import { ApplicationService } from '../../services/application.service';
import { LoadingService } from '../../services/loading.service';
import { MatomoConfigService } from '../../services/matomo-config.service';
import { PlanningToolService } from '../../services/planning-tool.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'ngx-hcp-menu',
  templateUrl: './hcp-menu.component.html',
  styleUrls: ['./hcp-menu.component.scss'],
})
export class HcpMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly dashboardLink = '/pages/dashboard';
  helpLink = environment.links.help;

  menuItems: DefaultMenuItem[] = [
    {
      route: this.dashboardLink,
      cssClass: '',
      title: this.translateService.instant('MAIN_MENU.START'), //TODO
      hidden: false,
    },
  ];

  appMenuItems: AppActionItem[] = [];
  accountMenu: AccountMenuItem[] = [];

  userEmail = '';
  applications!: ApplicationDTO[];

  private subscriptions = new Subscription();
  appIds = ApplicationIds;

  constructor(
    private l1l3AuthService: L1L3AuthService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private planningToolService: PlanningToolService,
    private utilService: UtilService,
    private loadingService: LoadingService,
    private MatomoConfigService: MatomoConfigService,
    private MatomoTracker: MatomoTracker
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.l1l3AuthService.isAuthenticatedSubject.subscribe({
        next: (isAuthenticated) => {
          if (isAuthenticated) {
            const userInfo = this.l1l3AuthService.getUserInfo();
            this.userEmail = userInfo.email;
          }
        },
      })
    );

    this.setupAccountMenu();
    this.getProfile();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.loadingService.loadingSubject.subscribe({
        next: (loading) => {
          const menuBar = document.getElementById('hekaMenuBar');

          if (menuBar) {
            menuBar.style.zIndex = loading ? '2000' : '';
          }
        },
      })
    );

    const helpLinkButton = document.getElementById('sel-menu-help-btn');

    if (helpLinkButton) {
      helpLinkButton.addEventListener('click', this.onHelpLinkClick.bind(this));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getProfile() {
    this.loadingService.showLoading();
    this.applicationService.getSharedProfile().subscribe({
      next: (profile) => {
        if (profile) {
          if (profile.applications?.length > 0) {
            this.applications = profile.applications;
          } else {
            this.applications = [];
          }
          this.setupApplicationsMenu();
        }
        this.loadingService.hideLoading();
      },
      error: (err) => {
        this.loadingService.hideLoading();
        throw err;
      },
    });
  }

  private setupAccountMenu() {
    const newAccountMenu = BasicAccountMenuItems;
    newAccountMenu[0].clickHandler = () => this.l1l3AuthService.goToProfile();
    newAccountMenu[1].clickHandler = () =>
      this.utilService.goToLink(
        environment.links.applications.mh2.host +
          environment.imprint.endpoint.paths.imprint
      );
    newAccountMenu[2].clickHandler = () => this.l1l3AuthService.logout();
    this.accountMenu = [
      newAccountMenu[0],
      newAccountMenu[1],
      newAccountMenu[2],
    ];
  }

  private setupApplicationsMenu() {
    this.appMenuItems = [];
    const unsortedMenuItems: AppActionItem[] = [];
    this.applications?.forEach((application) => {
      const foundAppConfig = APP_CONFIGS.find(
        (i) => i.appId === application.id
      );
      if (foundAppConfig) {
        let appMenuItem = new AppActionItem(foundAppConfig);
        this.applicationService.setAppActionItemClickAction(appMenuItem);
        unsortedMenuItems.push(appMenuItem);
      }
    });
    this.appMenuItems = unsortedMenuItems.sort(
      (a, b) => a.config.order - b.config.order
    );
    if (this.appMenuItems.findIndex((item) => item.config.isCurrentApp) < 0) {
      this.l1l3AuthService.logout();
    }
  }

  onHelpLinkClick(event: Event): void {
    console.log('Help button clicked');
    this.MatomoTracker.trackLink(this.helpLink, 'link');
  }
}
