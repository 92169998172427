import {Injectable} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {GlobalLoadingComponent} from 'src/app/components/global-loading/global-loading.component';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private overlayRef!: OverlayRef | null;
  private loadingCounter = 0;

  private _loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(private overlay: Overlay) {
  }

  get loadingSubject(): BehaviorSubject<boolean> {
    return this._loadingSubject;
  }

  showLoading() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: true
      });
      this.overlayRef.attach(new ComponentPortal(GlobalLoadingComponent));
      this._loadingSubject.next(true);
    } else {
      this.loadingCounter++;
    }
  }

  hideLoading() {
    if (this.overlayRef && this.loadingCounter <= 0) {
      this.overlayRef.detach();
      this._loadingSubject.next(false);
      this.overlayRef = null;
    } else {
      if (this.loadingCounter > 0) {
        this.loadingCounter--;
      }
    }
  }
}
