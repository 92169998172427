import { ApplicationIds } from './application';

export interface IAppConfig {
  appId: ApplicationIds;
  translationTitle: string;
  menuTitle?: string;
  translationContent: string;
  imgSrc: string;
  isCurrentApp: boolean;
  showAsWidget: boolean;
  order: number;
}

export const APP_CONFIGS: IAppConfig[] = [
  {
    appId: ApplicationIds.HPLUS_SERVICE,
    imgSrc: 'assets/img/hekatron-icons/office.png',
    translationTitle: 'APPS.SERVICE.TITLE',
    menuTitle: 'HPlus Office',
    translationContent: 'APPS.SERVICE.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: true,
    order: 1
  },
  {
    appId: ApplicationIds.CUSTOMER_PORTAL,
    imgSrc: 'assets/logo/meinhplusservice.png',
    translationTitle: 'APPS.CUSTOMER_PORTAL.TITLE',
    translationContent: 'APPS.CUSTOMER_PORTAL.DESCRIPTION',
    isCurrentApp: true,
    showAsWidget: false,
    order: 0
  },
  {
    appId: ApplicationIds.LEVEL_2,
    imgSrc: 'assets/logo/meinhplusservice.png',
    translationTitle: 'APPS.LEVEL_2.TITLE',
    translationContent: 'APPS.LEVEL_2.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: false,
    order: 4
  },
  {
    appId: ApplicationIds.ELECTRICITY_CALCULATION_TOOL,
    imgSrc: 'assets/img/hekatron-icons/stromberechnung.png',
    translationTitle: 'APPS.ELECTRICITY_CALCULATION_TOOL.TITLE',
    translationContent: 'APPS.ELECTRICITY_CALCULATION_TOOL.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: true,
    order: 2
  },
  {
    appId: ApplicationIds.TENDER_TOOL,
    imgSrc: 'assets/img/hekatron-icons/ausschreibung.png',
    translationTitle: 'APPS.TENDER_TOOL.TITLE',
    translationContent: 'APPS.TENDER_TOOL.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: true,
    order: 3
  },
  {
    appId: ApplicationIds.HPLUS_DONGLE,
    imgSrc: 'assets/img/hekatron-icons/dongle.png',
    translationTitle: 'APPS.HPLUS_DONGLE.TITLE',
    translationContent: 'APPS.HPLUS_DONGLE.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: true,
    order: 5
  },
  {
    appId: ApplicationIds.REMOTE_ADMIN,
    imgSrc: 'assets/img/hekatron-icons/fernzugriff.png',
    translationTitle: 'APPS.REMOTE_ADMIN.TITLE',
    translationContent: 'APPS.REMOTE_ADMIN.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: true,
    order: 6
  },
  {
    appId: ApplicationIds.REMOTE_DESKTOP,
    imgSrc: 'assets/img/hekatron-icons/fernzugriff.png',
    translationTitle: 'APPS.REMOTE_DESKTOP.TITLE',
    translationContent: 'APPS.REMOTE_DESKTOP.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: true,
    order: 7
  },
  {
    appId: ApplicationIds.OMS_TOOL,
    imgSrc: 'assets/img/hekatron-icons/dashboard.png',
    translationTitle: 'APPS.OMS_TOOL.TITLE',
    translationContent: 'APPS.OMS_TOOL.DESCRIPTION',
    isCurrentApp: false,
    showAsWidget: true,
    order: 8
  }
];

export class AppActionItem {
  config: IAppConfig;
  clickHandler: Function = () => {};

  constructor(config: IAppConfig) {
    this.config = config;
  }

  onClick() {
    this.clickHandler();
  }
}
