<ngx-heka-menu-bar id="hekaMenuBar" [defaultMenuItems]="menuItems" [helpLink]="helpLink" [userEmail]="userEmail"
                   [accountMenuItems]="accountMenu">
  <img src="assets/img/hekatron-icons/dashboard.png" routerLink="{{dashboardLink}}" menu-img>
  <button class="ms" id="sel-admin-menu-btn" mat-icon-button [matMenuTriggerFor]="appMenu"
          [disabled]="appMenuItems.length === 0" app-menu>
    <mat-icon>dashboard</mat-icon>
  </button>
</ngx-heka-menu-bar>

<mat-menu #appMenu="matMenu" class="app-menu">
  <ng-container *ngIf="appMenuItems.length > 0">
    <ng-container *ngFor="let item of appMenuItems">
      <button *ngIf="item.config.appId===appIds.CUSTOMER_PORTAL; else normalAppItem" mat-menu-item
              class="sel-admin-menu app-menu-item border-bottom" [class.active]="item.config.isCurrentApp" (click)="item.onClick()">
        <mat-icon>dashboard</mat-icon>
        <span>{{item.config.translationTitle|translate}}</span>
      </button>
      <ng-template #normalAppItem>
        <button mat-menu-item class="sel-admin-menu app-menu-item"  [class.active]="item.config.isCurrentApp" (click)="item.onClick()">
          <mat-icon>apps</mat-icon>
          <span>{{(item.config.menuTitle) || (item.config.translationTitle|translate)}}</span>
        </button>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-menu>
