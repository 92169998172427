export const ENVIRONMENT_GLOBAL = {
  envName: '',
  production: false,
  loggingEnabled: true,
  appVersion: require('../../package.json').version,
  redirect: {
    host: '',
    route: '/oauth'
  },
  auth_L1: {
    client_id: 'serviceportal',
    scope: 'phone email profile openid',
    endpoint: {
      host: '',
      paths: {
        prefix: '/auth/realms/Securicloud',
        account: '/account',
        login: '/protocol/openid-connect/auth',
        token: '/protocol/openid-connect/token',
        certs: '/protocol/openid-connect/certs',
        logout: '/protocol/openid-connect/logout',
        register: '/protocol/openid-connect/registrations'
      }
    }
  },
  backend: {
    scope: 'ssc',
    client_id: '',
    endpoint: {
      host: '',
      paths: {
        prefix: '/api',
        token: '/auth/token',
        logout: '/auth/logout'
      }
    }
  },
  links: {
    help: 'https://www.meinhplus.de/lernen/faq-haeufig-gestellte-fragen',
    applications: {
      mh2: {
        paths: {
          service: '/pages/mgmt',
          products: '/pages/product-knowledge/catalogs/d77f7058-072c-4eeb-ad1b-14bd1829b13b/products',
          downloads: '/pages/product-knowledge/downloads'
        }
      },
      elec_calc_tool: 'https://www.hekatron-planungstools.de/stromberechnung.php',
      tender_tool_extend: 'https://www.hekatron-planungstools.de/Extend.php',
      tender_tool_light: 'https://www.hekatron-planungstools.de/light.php',
      dongle: 'https://platform.meinhplus.de/#/licenses/dongle-management',
      remote_admin: 'https://remote.meinhplus.de/admin',
      remote_desktop: 'https://remote.meinhplus.de/auth/'
    },
    support: {
      host: 'https://www.meinhplus.de',
      paths: {
        knowledge: '/lernen',
        steps: '/erste-schritte',
        faq: '/faq-haeufig-gestellte-fragen',
        tutorials: '/tutorials/fuer-feststellanlagen-und-andere-gewerke'
      }
    }
  },
  imprint: {
    endpoint: {
      paths: {
        imprint: '/impressum'
      }
    }
  },
  users: {
    endpoint: {
      paths: {
        prefix: '/api/users',
        profile: "/profile",
        applications: "/applications",
        onboardingCompleted: "/onboarding-completed"
      }
    }
  },
  domaindata: {
    endpoint: {
      paths: {
        prefix: '/api/domain',
        companydata: "/companydata"
      }
    }
  }
};
