import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OauthComponent} from './oauth/oauth.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {GlobalLoadingComponent} from './global-loading/global-loading.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {HcpMenuComponent} from './hcp-menu/hcp-menu.component';
import {MatMenuModule} from "@angular/material/menu";
import {NgxHekaDesignLibModule} from "ngx-heka-design-lib";
import {RouterModule} from "@angular/router";
import {NoAppAccessComponent} from "./no-app-permission/no-app-access.component";

@NgModule({
  declarations: [
    OauthComponent,
    GlobalLoadingComponent,
    HcpMenuComponent,
    NoAppAccessComponent

  ],
  exports: [
    HcpMenuComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    NgxHekaDesignLibModule,
    RouterModule
  ]
})
export class ComponentsModule {
}
