import { MatomoTracker } from 'ngx-matomo-client';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private MatomoTracker: MatomoTracker) {}
  goToLink(link: string) {
    this.MatomoTracker.trackLink(link, 'link');
    window.open(link, '_blank', 'noopener,noreferrer');
  }
  isNS_ERROR(e: Error) {
    return e.name === 'NS_ERROR_FAILURE';
  }
}
