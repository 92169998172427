import { FormDataRequestService, L1L3AuthService } from 'ngx-auth-util-lib';
import { MatomoTracker } from 'ngx-matomo-client';
import { finalize } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/local/environment';
import { ApplicationIds } from '../models/application';
import { CompanyDataMgmtService } from './companydatamgmt.service';
import { NotificationService } from './error-handling/notification.service';
import { LoadingService } from './loading.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class PlanningToolService {
  constructor(
    private companyDataMgmtService: CompanyDataMgmtService,
    private loadingService: LoadingService,
    private authService: L1L3AuthService,
    private utilService: UtilService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private formDataRequestService: FormDataRequestService,
    private MatomoTracker: MatomoTracker
  ) {}

  openTool(applicationId: ApplicationIds) {
    this.loadingService?.showLoading();
    this.companyDataMgmtService
      .getCompanyData()
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe({
        next: (value) => {
          let userInfo = this.authService.getUserInfo();
          let path = this.getPath(applicationId, value.showAdditionalDocuments);
          if (value?.id && userInfo && path.length > 0) {
            this.MatomoTracker.trackLink(path, 'link');
            try {
              this.formDataRequestService.sendRequest(
                path,
                {
                  lgkCompany: value.companyName,
                  lgkCustNum: value.companyCustomerNumber,
                  lgkPerson: userInfo.display_name,
                  lgkPersNum: '',
                  lgkPostCode: value.address?.postalcode,
                  lgkEmail: userInfo.email,
                  lgkPhone: userInfo.phone_number,
                  lgkCustGroup: 'Extern',
                  lgkLocation: value.address?.city,
                },
                true,
                'POST'
              );
            } catch (e) {
              if (this.utilService.isNS_ERROR(e)) {
                alert(this.translateService.instant('ERRORS.ALLOW_POP_UPS'));
              } else {
                this.notificationService.showError(
                  this.translateService.instant('ERRORS.UNEXPECTED_ERROR')
                );
              }
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  private showErrorMessage(err: any): void {
    // TODO
    throw err;
  }

  private getPath(
    applicationId: string,
    showAdditionalDocuments: boolean
  ): string {
    if (applicationId === ApplicationIds.ELECTRICITY_CALCULATION_TOOL) {
      return environment.links.applications.elec_calc_tool;
    }
    if (applicationId === ApplicationIds.TENDER_TOOL) {
      if (showAdditionalDocuments) {
        return environment.links.applications.tender_tool_extend;
      } else {
        return environment.links.applications.tender_tool_light;
      }
    }
    // should not happen
    return '';
  }
}
