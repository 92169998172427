import {Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
// import {ERROR_CODES} from "../../models/codes";
import {environment} from 'src/environments/local/environment';
import {AuthErrorCodes, ERROR_CODES} from "../../models/codes";
import {L1L3AuthService} from "ngx-auth-util-lib";
import {Router} from "@angular/router";
import {ApplicationService} from "../application.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private translateService: TranslateService,
              private authService: L1L3AuthService,
              private router: Router,
              private applicationService: ApplicationService) {
  }

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return this.translateService.instant('ERRORS.NO_INTERNET');
    }
    if (environment.production) {
      return this.translateService.instant('ERRORS.UNEXPECTED_CLIENT_ERROR')
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string | undefined {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return <string>this.generateHttpResponseErrorMessage(error);
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }

  generateHttpResponseErrorMessage(err: HttpErrorResponse) {
    let errorMessage: string | null = '';
    if (err.status) {
      switch (err.status) {
        case 400:
          if (err.error?.code) {
            errorMessage = this.handle400Errors(err);
          }
          break;
        case 401:
          errorMessage = this.translateService.instant('ERRORS.NO_PERMISSION');
          break;
        case 403:
          errorMessage = this.translateService.instant('ERRORS.NO_PERMISSION');
          break;
        case 428:
          errorMessage = this.handleOnboarding(err);
          return errorMessage; //no notification necessary
          break;
        case 500:
          errorMessage = this.translateService.instant('ERRORS.INTERNAL_SERVER_ERROR');
          break;
        case 503:
          errorMessage = this.translateService.instant('ERRORS.PERSISTENCE_NOT_AVAILABLE');
          break;
      }
    }
    if (!errorMessage) {
      errorMessage = this.translateService.instant('ERRORS.UNEXPECTED_ERROR');
    }
    return errorMessage;
  }

  createUnknownCodeMessage(error: HttpErrorResponse): string {
    let message = this.translateService.instant('ERRORS.UNEXPECTED_ERROR');
    let code = "";
    if (error.status) {
      code = "Code: ";
      if (error.status === 400 && error.error?.code) {
        code = code + error.error.code;
      } else {
        code = code + error.status;
      }
      message = message + ' ' + code;
    }
    return message;
  }

  private handleOnboarding(err: HttpErrorResponse): string | null {
    if (this.isSecuredHost(err.url) &&
      err.url.indexOf(this.authService.l3Config.host+this.authService.l3Config.paths.auth) !== -1) {
      if (err?.error && err.error.code >= 0) {
        this.checkOnboarding(err.error.code);
        return null; //no notification necessary
      }
    }
    return this.translateService.instant('ERRORS.UNEXPECTED_ERROR');
  }

  private isSecuredHost(url: string): boolean {
    return this.authService.securedHosts.some((n: string) => url.includes(n));
  }

  private checkOnboarding(code: number): void {
    switch (code) {
      case AuthErrorCodes.NO_COMPANY_DATA:
        window.open(environment.links.applications.l2.host, "_self");
        break;
      case AuthErrorCodes.NO_APP_PERMISSION:
        this.applicationService.setAppAccess(false);
        this.router.navigate(['no-app-access']);
        break;
      default:
        this.authService.logout();
        break;
    }
  }

  private handle400Errors(err: HttpErrorResponse) {
    let errorMessage = '';
    switch (err.error.code) {
      case ERROR_CODES.COMPANYDATA_NOT_FOUND:
        errorMessage = this.translateService.instant('COMPANYDATA.ERROR.COMPANYDATA_NOT_FOUND');
        break;
      default:
        errorMessage = this.createUnknownCodeMessage(err);
        break;
    }
    return errorMessage;
  }
}
