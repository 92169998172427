import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxAuthUtilLibModule, TokenInterceptor } from 'ngx-auth-util-lib';
import { NgxHekaDesignLibModule } from 'ngx-heka-design-lib';
import { provideMatomo, withRouter } from 'ngx-matomo-client';
import { environment } from 'src/environments/local/environment';

import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { GlobalErrorHandler } from './services/error-handling/global-error-handler';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeDe);

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('de');
    return translate.use('de').toPromise();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxAuthUtilLibModule,
    NgxHekaDesignLibModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ComponentsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideMatomo({ scriptUrl: environment.matomoSrc }, withRouter()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
