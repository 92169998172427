import * as deepmerge from 'deepmerge';

import { ENVIRONMENT_GLOBAL } from '../global_env';

export const environment = deepmerge(ENVIRONMENT_GLOBAL, {
  envName: 'test',
  production: true,
  matomoSrc: 'https://matomo.meinhplus.de/statistic/js/container_EfEzdlyc_test_d8abcd35cbfd5f1a7ff6fc23.js',
  redirect: {
    host: 'https://test-dashboard.meinhplus.de'
  },
  auth_L1: {
    client_id: 'customerportal',
    scope: 'phone email profile openid',
    endpoint: {
      host: 'https://test-account.meinhplus.de',
      paths: {
        prefix: '/auth/realms/hekatron-tuer-tor'
      }
    }
  },
  backend: {
    scope: 'hcp',
    client_id: 'hcp-webbapp-cH0w6GlrnNn5mkXn',
    endpoint: {
      host: 'https://api.test-dashboard.meinhplus.de'
    }
  },
  files: {
    paths: {
      host: 'https://test-dashboard.meinhplus.de' //TODO check
    }
  },
  links: {
    applications: {
      mh2: {
        host: 'https://test.meinhplus.de'
      },
      l2: {
        host: 'https://test-administration.meinhplus.de'
      },
      oms: {
        host: 'https://test-oms.meinhplus.de'
      }
    }
  }
});
