<div class="oauth-container" *ngIf="error || showWaitingAreaCounter>0">
  <div class="header">
    <img src="assets/img/hekatron-icons/dashboard.png">
  </div>
  <ngx-heka-general-error *ngIf="error != null && showWaitingAreaCounter!==1"
                          [title]="'OAUTH.ERROR.TITLE' | translate"
                          [description]="'OAUTH.ERROR.DESCRIPTION' | translate">
    <div class="mb-4 ngx-heka-hint" *ngIf="invalidCodeMessage">Error: {{error}}</div>
    <button mat-flat-button color="primary" (click)="authService.logout()">{{'OAUTH.LOGIN_BTN' | translate}}</button>
  </ngx-heka-general-error>
  <ngx-heka-waiting-area *ngIf="showWaitingAreaCounter===1" class="mt-lg-5"
                         [title]="'OAUTH.WAITING.TITLE'|translate"
                         [steps]="steps"
                         [infoText]="'OAUTH.WAITING.INFO'|translate"
                         (onFinishWaiting)="onFinishWaiting()"
                         [loadingTimePerStep]="2000">
  </ngx-heka-waiting-area>
</div>
<div *ngIf="loading" class="loading-container">
  <mat-spinner [diameter]="40"></mat-spinner>
  <b class="ms-4">{{'OAUTH.LOGIN'|translate}}</b>
</div>
